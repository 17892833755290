import './App.css';
import {useEffect} from "react";
import axios from "axios";

function App() {

    useEffect(() => {
        const sendVisitorInfo = async () => {
            try {
                const response = await axios.get('https://ipapi.co/json/');
                const visitorInfo = {
                    ip: response.data.ip,
                    city: response.data.city,
                    region: response.data.region + ', ' + response.data.country_capital,
                    country: response.data.country_name,
                    userAgent: navigator.userAgent,
                    referrer: document.referrer,
                    portfolio_name: "timilehin.dev",
                };

                console.log('Visitor info:', visitorInfo);
                await axios.post('https://us-central1-tim-portfolios.cloudfunctions.net/newVisitorNotification', visitorInfo);

                window.location.href = 'https://cosmic-methane-022.notion.site/Hey-I-m-Timilehin-Jegede-a652714e2a964780aa7cc61d09071890';
            } catch (error) {
                console.error('Error sending visitor info:', error);
            }
        };

        sendVisitorInfo();
    }, []);

  return (
    <div className="App">
      <header className="App-header">
      </header>
    </div>
  );
}

export default App;
